<template>
  <!--  -->
  <a-popover title="图标" trigger="click" v-model="popoverVisible">
    <template slot="content">
      <div :class="prefixCls" style="width: 500px;">
        
        <a-tabs v-if="type === 1" v-model="currentTab" @change="handleTabChange">
          <a-tab-pane v-for="v in icons" :tab="v.title" :key="v.key">
            <ul>
              <li v-for="(icon, key) in v.icons" :key="`${v.key}-${key}`" :class="{ 'active': selectedIcon==icon }" @click="handleSelectedIcon(icon)" >
                <a-icon :type="icon" :style="{ fontSize: '25px' }" />
              </li>
            </ul>
          </a-tab-pane>
        </a-tabs>
        <div v-else class="img-wrap">
          <img v-for="(item, index) in iconList" :key="index" :src="item.url" :alt="item.menuIcon" class="img" @click="handleSelectedMenuIcon(item)">
        </div>
      </div>
    </template>
    <slot :url='selectedMenuIcon'></slot>
  </a-popover>
</template>

<script>
import icons from './icons'
import { menuIcon } from '@/api/menu'

export default {
  name: 'IconSelect',
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-icon-selector'
    },
    value: {
      type: String,
      default: ''
    },
    // 1为内部icon, 2外部地址图标
    type: {
      type: Number,
      default: 1
    },
    /***
     * 外地地址传入
     * 格式：
     * {
     *    menuIcon: item.menuIcon,
     *    url: item.url
     * }
     */
    iconList: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      selectedIcon: this.value || '',
      selectedMenuIcon: '',
      currentTab: 'brand_logo',
      popoverVisible: false,
      icons
    }
  },
  watch: {
    value (val) {
      this.selectedIcon = val
      this.autoSwitchTab()
    }
  },
  created () {
    if (this.value) {
      this.autoSwitchTab()
    }
  },
  mounted() {
    this.getMenuIcon()
  },
  methods: {
    getMenuIcon() {
      // menuIcon().then(res => {
      //   console.log('res', res)
      //   if (res.code === 200 && res.rows) {
      //     this.iconList = res.rows.map(val => {
      //       val.url = process.env.VUE_APP_API_FILE_URL + val.url
      //       return val
      //     })
      //   }
      // }).catch(e => {
      //   console.error(e)
      // })
    },
    handleSelectedMenuIcon(item) {
      let row = {
        menuIcon: item.menuIcon,
        url: item.url
      }
      this.selectedMenuIcon = item.url
      this.$emit('select', row)
      this.closePopover()
    },
    handleSelectedIcon (icon) {
      this.selectedIcon = icon
      this.$emit('change', icon)
      this.closePopover()
    },
    handleTabChange (activeKey) {
      this.currentTab = activeKey
    },
    // 父组件可调用
    openPopover () {
      this.popoverVisible = true
    },
    // 父组件可调用
    closePopover () {
      this.popoverVisible = false
    },
    autoSwitchTab () {
      icons.some(item => item.icons.some(icon => icon === this.value) && (this.currentTab = item.key))
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../index.less";

  ul{
    list-style: none;
    padding: 0;
    overflow-y: scroll;
    height: 250px;

    li{
      display: inline-block;
      padding: @padding-sm;
      margin: 3px 0;
      border-radius: @border-radius-base;

      &:hover, &.active{
        // box-shadow: 0px 0px 5px 2px @primary-color;
        cursor: pointer;
        color: @white;
        background-color: @primary-color;
      }
    }
  }

  .img-wrap {
    display: flex;
    flex-flow: wrap;
    .img {
      width: 60px;
      height: 60px;
      padding: 4px;
      &:hover {
        box-shadow: 0px 0px 1px 1px @primary-color;
        cursor: pointer;
        color: @white;
        background-color: @primary-color;
        border-radius: 5px;
      }
    }

  }
</style>
